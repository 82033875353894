import { Link } from "react-router-dom";
import env1 from "../../assets/footer/env1.png";
import env2 from "../../assets/footer/env2.png";
import env3 from "../../assets/footer/env3.png";
import env4 from "../../assets/footer/env4.png";
import env5 from "../../assets/footer/env5.png";
import env6 from "../../assets/footer/env6.png";
import env7 from "../../assets/footer/env7.png";
import linkedin from "../../assets/footer/linkedin.png";
import logo from "../../assets/footer/footerLogo.png";

function Footer() {
  const altDescription = "Symbol for Sustainable Development Goal";

  const images = [
    {
      src: env1,
      alt: "Clean Water and Sanitation",
      link: "https://sdgs.un.org/goals/goal6",
    },
    {
      src: env2,
      alt: "Sustainable Cities and Communities",
      link: "https://sdgs.un.org/goals/goal11",
    },
    {
      src: env3,
      alt: "Responsible Consumption and Production",
      link: "https://sdgs.un.org/goals/goal12",
    },
    {
      src: env4,
      alt: "Climate Action",
      link: "https://sdgs.un.org/goals/goal13",
    },
    {
      src: env5,
      alt: "Life Below Water",
      link: "https://sdgs.un.org/goals/goal14",
    },
    {
      src: env6,
      alt: "Life on Land",
      link: "https://sdgs.un.org/goals/goal15",
    },
    {
      src: env7,
      alt: "Partnerships for the Goals",
      link: "https://sdgs.un.org/goals/goal17",
    },
  ];

  const handleClick = (event, link) => {
    event.preventDefault();
    window.open(link, "_blank", "noopener,noreferrer");
  };
  return (
    <footer
      className="footer px-7 py-5 poppins-regular w-full"
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "25rem",
        alignItems: "flex-start",
      }}
    >
      <div className="w-full flex flex-wrap justify-content-between ">
        <div className="flex flex-column gap-2 mt-4">
          <text className="text-2xl font-semibold capitalize">Kuyua GmbH</text>
          <section className="flex flex-column">
            <text>Bornstraße 3</text>
            <text>20146 Hamburg</text>
            <text>Commercial Register: HRB 182987</text>
            <text>Registration court: Hamburg</text>
          </section>
          <div className="h-full flex align-items-end gap-2">
            {images.map((image, index) => (
              <Link
                key={index}
                to="#"
                onClick={(event) => handleClick(event, image.link)}
                style={{ height: 55, width: 55 }}
              >
                <img src={image.src} alt={image.alt} width={55} height={55} />
              </Link>
            ))}
          </div>
        </div>

        <div className="flex gap-2 justify-content-center mt-4">
          <div className="flex flex-column gap-2">
            <text className="text-2xl font-semibold uppercase">about us</text>
            <text>
              Our mission is to empower <br /> companies to comprehend <br />{" "}
              their impact on nature, as <br /> well as their dependencies{" "}
              <br /> and associated business <br /> risks – and to show paths{" "}
              <br /> towards safeguarding <br /> both, natural and capital{" "}
              <br /> assets.
            </text>
          </div>
        </div>
        <div className="flex flex-column gap-3 align-items-end justify-content-end mt-4">
          <Link
            to="/contact"
            className="text-2xl uppercase text-white no-underline font-semibold"
          >
            contact us
          </Link>
          <Link
            className="text-2xl uppercase text-white no-underline font-semibold"
            to="/jobs"
          >
            jobs
          </Link>
          <Link
            className="text-2xl uppercase text-white no-underline font-semibold"
            to="https://calendly.com/kya-christian/30?month=2024-06g"
          >
            book a demo
          </Link>
          <Link
            className="text-2xl uppercase text-gray-500 no-underline font-light"
            to="https://www.kuyua.com/privacy-policy"
          >
            imprint & privacy
          </Link>
          <div className="h-full flex align-items-end">
            <Link to="https://www.linkedin.com/company/kuyua">
              <img src={linkedin} alt="kuyua linkedin profile" />
            </Link>
          </div>
        </div>
      </div>
      <div className="w-full">
        <div className="logo-container text-center w-full">
          <img className="w-full" src={logo} alt="kuyua website logo" />
        </div>
        <small>© 2024 KUYUA</small>
      </div>
    </footer>
  );
}

export default Footer;
