import { useEffect, useRef, useState } from "react";
import "./ShowCaseSection.scss";
import macbook from "../../../assets/macbook.png";
import { Link } from "react-router-dom";

function ShowCaseSection() {
  const macbookRef = useRef(null);
  const textRef = useRef(null);
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  useEffect(() => {
    const checkScreenSize = () => {
      setIsSmallScreen(window.innerWidth < 1280); // Adjust this value as needed
    };

    checkScreenSize();
    window.addEventListener("resize", checkScreenSize);

    return () => {
      window.removeEventListener("resize", checkScreenSize);
    };
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (isSmallScreen) return; // Don't apply parallax on small screens

      const scrollY = window.scrollY;

      if (macbookRef.current) {
        macbookRef.current.style.transform = `translateY(${scrollY * -0.28}px)`;
      }

      if (textRef.current) {
        textRef.current.style.transform = `translateY(${scrollY * 0.06}px)`;
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isSmallScreen]);

  const handleClick = (event) => {
    event.preventDefault();
    window.open(
      "https://calendly.com/kya-christian/30?month=2024-06",
      "_blank",
      "noopener,noreferrer"
    );
  };

  return (
    <div className="">
      <section className="show-case poppins-regular overflow-hidden">
        {/* Parallax section */}
        <div className="front-show px-4 py-4" ref={textRef}>
          <h2 className="helve-font sticky-text">
            Driven by <br /> data, <br />
            inspired by <br /> nature.
          </h2>

          <div className="parent-container">
            <img
              className="macbook-image"
              src={macbook}
              alt="kuyua website on macbook"
              ref={macbookRef}
            />
          </div>
        </div>

        <div className="show-description">
          <h2 className="helve-font">
            Aligned with international standards <br /> providing actionable
            data
          </h2>
          <p>
            Our AI-powered platform provides you with consolidated data on
            nature <br />
            risks of any production site or supplier location. The holistic
            materiality <br /> assessment and scoring enable your company to
            identify and prioritize <br /> tailored action plans for risk
            reduction and improvement.
          </p>

          <button className="w-10 md:w-13rem demo-btn">
            <Link to="#" onClick={handleClick}>
              Book a demo
            </Link>
          </button>
        </div>
      </section>
    </div>
  );
}

export default ShowCaseSection;
