import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "./Standards.scss";

const StandardSection = ({ standards, customizeStandardsClass, title }) => {
  return (
    <section className="standards-section w-full h-15rem py-5">
      <h3 className="poppins-regular w-full text-xl md:text-4xl">{title}</h3>
      <div className="standard-logo w-full h-6rem px-6 overflow-hidden">
        <div className="scroll-container">
          <div className="scroll-imgs">
            {/* Original logos */}
            {standards.map((logo, index) => (
              <div className="logo-item" key={`brand${index}`}>
                <img
                  src={logo.img}
                  alt="partners brands logos"
                  style={{
                    width: logo.width,
                    height: "4rem",
                  }}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default StandardSection;
