import "./App.css";
import Footer from "./components/footer/Footer";
import Header from "./components/header/Header";
import BrandSection from "./pages/home/brands/BrandSection";
import DescriptionSection from "./pages/home/description/DescriptionSection";
import FeaturesSection from "./pages/home/features/FeaturesSection";
import HeroSection from "./pages/home/hero/HeroSection";
import TestmonialsSection from "./pages/home/testmonials/TestmonialsSection";
import BlogSection from "./pages/home/blog/BlogSection";

import metro from "./assets/brands/metro.png";
import jungheinrich from "./assets/brands/jungheinrich.png";
import melita from "./assets/brands/melita.png";
import fuchs from "./assets/brands/fuchs.png";
import vaude from "./assets/brands/vaude.png";
import baum from "./assets/brands/baum.png";
import marc_opolo from "./assets/brands/marc_opolo.png";
import impact_solutions_commerzbank from "./assets/brands/impact_solutions_commerzbank.png";
import umweltstiftung_michael_otto from "./assets/brands/umweltstiftung_michael_otto.png";
import ufz from "./assets/brands/ufz.png";
import scoreMore4 from "./assets/brands/Score4More.png";
import ic from "./assets/brands/Implement Consulting.jpeg";
import hsolar from "./assets/brands/hep_solar.png";
import gf from "./assets/brands/Georg Fischer.svg.png";
import co from "./assets/brands/climate_collective.png";
import bdv from "./assets/brands/biodiversity_in_good_company.png";
import epp from "./assets/brands/eppendorf.png";
import basf from "./assets/brands/basf.png";
import eutaxonomy from "./assets/standards/eu_taxonomy.png";
import gri from "./assets/standards/gri.png";
import csrd from "./assets/standards/csrd.png";
import sbtn from "./assets/standards/sbtn.png";
import issb from "./assets/standards/issb.png";
import sfdr from "./assets/standards/sfdr.png";
import tnfd from "./assets/standards/tnfd.png";
import ShowCaseSection from "./pages/home/show-case/ShowCaseSection";
import StandardSection from "./pages/standards/Standards";

const brandLogos = [
  { width: null, height: "2.6rem", img: melita, col: 2 },
  { width: null, height: "2.4rem", img: jungheinrich, col: 2 },
  { width: null, height: "3.2rem", img: basf, col: 2 },
  { width: null, height: "1.7rem", img: metro, col: 2 },
  { width: null, height: "4.0rem", img: fuchs, col: 2 },
  { width: null, height: "1.7rem", img: gf, col: 2 },
  { width: null, height: "2.0rem", img: vaude, col: 3 },
  { width: null, height: "2.7rem", img: hsolar, col: 2 },
  { width: null, height: "1.7rem", img: epp, col: 2 },
  { width: null, height: "1.3rem", img: marc_opolo, col: 2 },
  { width: null, height: "3.1rem", img: ufz, col: 2 },
  { width: null, height: "1.8rem", img: scoreMore4, col: 2 },
  { width: null, height: "3.3rem", img: ic, col: 2 },
  { width: null, height: "2.7rem", img: co, col: 2 },
  { width: null, height: "3.3rem", img: bdv, col: 2 },
  { width: null, height: "2.6rem", img: baum, col: 2 },
  { width: null, height: "3.0rem", img: impact_solutions_commerzbank, col: 2 },
  { width: null, height: "3.0rem", img: umweltstiftung_michael_otto, col: 2 },
];

const standardLogos = [
  { width: null, height: "3.2rem", img: eutaxonomy, col: 2 },
  { width: null, height: "3.2rem", img: gri, col: 2 },
  { width: null, height: "3.2rem", img: csrd, col: 2 },
  { width: null, height: "3.2rem", img: sbtn, col: 4 },
  { width: null, height: "3.2rem", img: issb, col: 2 },
  { width: null, height: "3.2rem", img: sfdr, col: 2 },
  { width: null, height: "3.2rem", img: tnfd, col: 2 },
];

function App() {
  return (
    <>
      <Header />
      <HeroSection />
      <DescriptionSection />
      <BrandSection
        logos={brandLogos}
        customizelogosClass="logo-brand-container"
        customizeGridGapClass="null"
        title="clients and partners"
      />
      <FeaturesSection />
      {/* <TestmonialsSection /> */}
      <StandardSection
        standards={standardLogos}
        customizeStandardsClass="logo-brand-container"
        title="Aligned with international standards and frameworks"
      />
      <ShowCaseSection />
      {/* comment out for now until we develop the blogs website */}
      {/* <BlogSection /> */}
      <Footer />
    </>
  );
}

export default App;
